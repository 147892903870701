import { Meta, Title } from '@angular/platform-browser';
import { environment } from '@environments/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class MetaService {
  constructor(
    private meta: Meta,
    private title: Title,
    // private gtmService: GoogleTagManagerService,
  ) { }

  // push GTM data layer for every visited page
  pushGtmTag(route): void {
    const gtmTag = {
      event: 'page',
      pageName: route.url
    };

    // this.gtmService.pushTag(gtmTag);
  }

  unsetRobots(): void {
    this.meta.addTag({ name: 'robots', content: 'noindex, nofollow' });
  }

  addMetaProperty(metaPropertyName: string, metaContent: string) {
    this.meta.addTag({ property: metaPropertyName, content: metaContent }, true);
  }

  updateMetaProperty(metaProperty: string, metaText: string): void {
    this.meta.updateTag(
      {
        property: metaProperty,
        content: metaText
      }
    );
  }

  updateMetaName(metaName: string, metaText: string): void {
    this.meta.updateTag(
      {
        name: metaName,
        content: metaText
      }
    );
  }

  setTitle(title: string): void {
    this.title.setTitle(title);
  }

  removeRobots(): void {
    this.meta.removeTag('name="robots"');
  }

  enableProdRobots(): void {
    if (environment.production) {
      this.removeRobots();
    }
  }
}
