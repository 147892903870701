<div class="position-relative bg-accent-blue p-2">

  <p
    *ngIf="hasTickets && card.tickets"
    class="position-absolute line-height-1 top-0 left-0 m-0 ml-2 mt-2 color-primary font-size-xs font-weight-bold"
    i18n="Free tickets available label@@label.freeTicketsAvailable">
    Free tickets available
  </p>

  <span
    *ngIf="card.is_expired"
    class="position-absolute top-0 right-0 mr-2 mt-2 badge-label stamp-status text-center badge-label-accent">
    <span i18n="Expired label@@label.expired">Expired</span>
  </span>

  <div class="d-flex pt-4">
    <div class="d-flex flex-grow-1 flex-column">
      <h3 class="m-0 mb-1 font-weight-bold">{{card.name}}</h3>

      <div class="card-info-item mb-3 font-size-xs">
        <span>{{card.provider.name}}</span>
      </div>

      <div class="card-info-item" *ngIf="showShare(card)">
        <button
          class="bg-white"
          mat-stroked-button
          color="primary"
          (click)="share(card)">
          <span i18n="Share Card button@@button.shareCard">Share Card</span>
        </button>
      </div>
    </div>

    <div class="d-flex flex-column align-items-end" *ngIf="hasPayment">
      <p class="m-0 mb-1 line-height-1 font-size-xs text-right" i18n="Leisure card balance@@label.leisureCardBalance">Balance</p>
      <p class="m-0 font-size-lg text-right">kr {{card.amount | number : '1.2-2' : 'nb-no'}}</p>
    </div>
  </div>
</div>

<div class="bg-accent-blue p-1" style="margin-top: 2px" *ngIf="hasPayment">
  <h4
    *ngIf="hasPaymentHistory() || true"
    aria-role="button"
    class="pointer w-100 m-0"
    tabIndex="0"
    (click)="showPaymentHistory = !showPaymentHistory"
    (keypress)="$event.key === 'Enter' && showPaymentHistory = !showPaymentHistory">
    <span
      *ngIf="!showPaymentHistory"
      i18n="Show payment history label@@label.showPaymentHistory"
      class="d-flex align-items-center justify-content-center">
      Show payment history
      <mat-icon class="color-primary icon icon-lg">keyboard_arrow_down</mat-icon>
    </span>
    <span
      *ngIf="showPaymentHistory"
      i18n="Hide payment history label@@label.hidePaymentHistory"
      class="d-flex align-items-center justify-content-center">
      Hide payment history
      <mat-icon class="color-primary icon icon-lg">keyboard_arrow_up</mat-icon>
    </span>
  </h4>

  <div *ngIf="showPaymentHistory">
    <table *ngFor="let item of card.history" class="transactions p-3 mb-2 w-100"
           [ngClass]="{'failed-transaction' : isFailed(item)}">
      <tr *ngIf="item.organization_name" class="card-info-item">
        <td i18n="To organisation@@label.transToOrg" class="text-label">To</td>
        <td>{{item.organization_name}}</td>
      </tr>
      <tr *ngIf="item.bank_account" class="card-info-item">
        <td class="text-label" i18n="To organisation@@label.transAccountNo">Account number</td>
        <td>{{item.bank_account}}</td>
      </tr>
      <tr *ngIf="item.kid" class="card-info-item">
        <td i18n="To organisation@@label.transKid" class="text-label">KID</td>
        <td>{{item.kid}}</td>
      </tr>
      <tr *ngIf="item.payment_note" class="card-info-item">
        <td i18n="To organisation@@label.transPaymentNot" class="text-label">Description</td>
        <td>{{item.payment_note}}</td>
      </tr>
      <tr class="card-info-item">
        <td i18n="To organisation@@label.transAmount" class="text-label">Amount</td>
        <td [ngClass]="{'line-through': isFailed(item)}">kr {{item.amount | number : '1.2-2' : 'nb-no'}}</td>
      </tr>
      <tr class="card-info-item" *ngIf="item.status === 'ROLLBACK'">
        <td class="text-label"></td>
        <td i18n="To organisation@@label.transRollback">Payment credited</td>
      </tr>
      <tr class="card-info-item">
        <td i18n="To organisation@@label.transDate" class="text-label">Date</td>
        <td>{{item.created_at | date : 'dd MMM yyyy'}}</td>
      </tr>
      <tr class="card-info-item" *ngIf="item.rejection_message">
        <td i18n="To organisation@@label.transRejectionMsg" class="text-label">Failed because</td>
        <td>{{item.rejection_message}}</td>
      </tr>
      <tr class="card-info-item" *ngIf="item.payment_status">
        <td i18n="To organisation@@label.transPaymentStatus" class="text-label">Payment status</td>
        <td [ngClass]="{'text-error': isFailed(item)}">{{item.payment_status}}</td>
      </tr>
      <tr class="card-info-item" *ngIf="item.retried_at">
        <td i18n="Retried Transaction Date@@label.retriedTransactionDate" class="text-label">Retried At</td>
        <td>{{item.retried_at | date : 'dd MMM yyyy'}}</td>
      </tr>
      <tr *ngIf="item.can_retry">
        <td></td>
        <td class="text-right">
          <button mat-button
                  class="mb-4 text-center"
                  color="primary"
                  [disabled]="item.isLoading"
                  (click)="retry(item)"
                  type="submit">
            <span class="text-uppercase" *ngIf="!item.isLoading"
                  i18n="Retry button label@@button.retryTransaction">Retry</span>
            <mat-spinner *ngIf="item.isLoading"
                         class="mx-auto"
                         [diameter]="20"
                         [strokeWidth]="2"></mat-spinner>
          </button>
        </td>
      </tr>
    </table>
  </div>
</div>
