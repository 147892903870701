import {UserMunicipality} from "@shared/models/user-municipality.model";

export const GiskeMunicipality: UserMunicipality = {
  name: 'giske',
  slogan: {value: '', id: 'banner.giske', meaning: 'bannerSlogan', description: 'slogan for giske'},
  data: {},
  bannerURL: '',
  bannerType: 'image',
  hasLeisureCard: false,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: true,
  sunset: false,
  hasPayment: false,
  hasTickets: false,
  infoPageTitle: 'Fritidskonto',
}
