import { LeisureCard, SharableLeisureCard } from '@shared/models/leisure-card.model';
import { Injectable } from '@angular/core';
import { GenericHttpService } from '@services/generic-http/generic-http.service';
import { API_URL_KEY, API_URL_MAP } from '@config/api-url.config';
import { Observable, of } from 'rxjs';
import {environment} from '@environments/environment';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LeisureCardService {

  constructor(
    private http: GenericHttpService
  ) { }

  public getBankIdUrl(): Observable<any> {
    const url = `${API_URL_MAP.card.bankId}${API_URL_MAP.card.auth}`;
    return this.http.get(API_URL_KEY.card, url);
  }

  public getMinIdUrl(): Observable<any> {
    const url = `${API_URL_MAP.card.minid}${API_URL_MAP.card.auth}`;
    return this.http.get(API_URL_KEY.card, url);
  }

  public getBankIdStatus(): Observable<any> {
    const url = `${API_URL_MAP.card.bankId}${API_URL_MAP.card.status}`;
    return this.http.get(API_URL_KEY.card, url);
  }

  // Returns 204 no content if shared already, full card if owned
  public checkCardBySsn(data: {ssn: string}): Observable<{data: LeisureCard}|null> {
    const url = `${API_URL_MAP.card.base}${API_URL_MAP.card.check}`;
    return this.http.post(API_URL_KEY.cardV2, url, data);
  }

  public createNewCard(cardData): Observable<any> {
    const url = `${API_URL_MAP.card.base}`;
    return this.http.post(API_URL_KEY.cardV2, url, cardData);
  }

  public takeSharedCard(cardData: {ssn: string, pin: string}): Observable<LeisureCard> {
    const url = `${API_URL_MAP.card.base}${API_URL_MAP.card.take}`;
    return this.http.post(API_URL_KEY.cardV2, url, cardData);
  }

  public shareCard(cardId: string): Observable<{data: SharableLeisureCard}> {
    const url = `${API_URL_MAP.card.base}/${cardId}${API_URL_MAP.card.share}`;
    return this.http.get(API_URL_KEY.cardV2, url);
  }

  private userCards$ : Observable<LeisureCard[]>;
  public get activeUserCards() {
    return this.getUserCards(true).pipe(
      map( (response: {data: LeisureCard[]}) => response.data )
    );
    // @TODO work around. refactor away from GETTER
    // if (!this.userCards$) {
    //   this.userCards$ = this.getUserCards(true).pipe(
    //     shareReplay(),
    //     map( (response: {data: LeisureCard[]}) => response.data ),
    //   );
    // }
    // return this.userCards$;
  }

  public getUserCards(onlyActive = false): Observable<any> {
    const url = `${API_URL_MAP.users.me}${API_URL_MAP.card.base}?active=${onlyActive}`;
    return this.http.get(API_URL_KEY.card, url).pipe(shareReplay(1, 5 * 60 * 1000));
  }

  public getCardHistory(cardId: string): Observable<any> {
    const url = `${API_URL_MAP.card.base}/${cardId}${API_URL_MAP.card.transactions}`;
    return this.http.get(API_URL_KEY.card, url);
  }

  public withdrawFromCard(cardId: string, amountData: any): Observable<any> {
    const url = `${API_URL_MAP.card.base}/${cardId}${API_URL_MAP.card.withdraw}`;
    return this.http.post(API_URL_KEY.card, url, amountData);
  }

  public getCardWithdrawDetails(cardId: string, invoicedata): Observable<any> {
    const url = `${API_URL_MAP.card.base}/${cardId}/payment_intent`;
    return this.http.post(API_URL_KEY.card, url, invoicedata);
  }

  public leisureCardPaymentConfirm(paymentIntentId: string, paymentMethod: string): Observable<any> {
    const url = `/payment_intents/${paymentIntentId}/confirm`;
    return this.http.post(API_URL_KEY.card, url, {payment_method: paymentMethod});
  }

  public getMunicipalityById(municipalityId: string): Observable<any> {
    return this.http.get(API_URL_KEY.card, `/municipalities/${municipalityId}`);
  }

  public getCard(cardId): Observable<any> {
    const url = `${API_URL_MAP.card.base}/${cardId}`;
    return this.http.get(API_URL_KEY.card, url);
  }

  public retryTransaction(transactionId): Observable<any> {
    const url = `${API_URL_MAP.transaction.base}/${transactionId}${API_URL_MAP.transaction.retry}`;
    return this.http.post(API_URL_KEY.card, url);
  }

  public uploadMembersList(fileData): Observable<any> {
    const url = `${API_URL_MAP.card.base}${API_URL_MAP.card.attachments}${API_URL_MAP.card.import}`;
    return this.http.postFile(API_URL_KEY.card, url, fileData);
  }

  public deleteMemberFromUploadedList(memberId: string) {
    const url = `${API_URL_MAP.card.base}${API_URL_MAP.card.attachments}/${memberId}`;
    return this.http.del(API_URL_KEY.card, url);
  }

  public getCultureCardsMembersList(page: number = 1, query?: string, filterParams: any = {}): Observable<any> {
    const searchParams: any = { ...filterParams };
    searchParams.page = page;
    if (query) {
      searchParams.q = query;
    }

    const url = `${API_URL_MAP.card.base}${API_URL_MAP.card.attachments}`;
    return this.http.get(API_URL_KEY.card, url, searchParams);
  }

  public applyAllLeisureCardsAmountChanges() {
    const url = `${API_URL_MAP.card.base}${API_URL_MAP.card.attachments}${API_URL_MAP.card.initialize}`;
    return this.http.post(API_URL_KEY.card, url, {});
  }

  public applyLeisureCardAmountChangeById(attachmentId: string) {
    const url = `${API_URL_MAP.card.base}${API_URL_MAP.card.attachments}/${attachmentId}${API_URL_MAP.card.initialize}`;
    return this.http.post(API_URL_KEY.card, url, {});
  }

  public refillLeisureCard(data) {
    const url = `${API_URL_MAP.card.base}${API_URL_MAP.card.attachments}/attach`;
    return this.http.post(API_URL_KEY.card, url, data);
  }

  public userHasLeisureCardSupportingTickets() {
    return this.activeUserCards.pipe(map(cards => {
      return cards.some(card => card.tickets);
    }))
  }
}
