import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const NamsosMunicipality: UserMunicipality = {
  name: 'namsos',
  slogan: {
    value: `Opplev Namsos <br> Finn din <a class="slogan" href="/info/about/fritidskonto">Fritidskonto</a> her`,
    id: 'banner.namsos',
    meaning: 'bannerSlogan',
    description: 'slogan for namsos',
  },
  data: {
    headerDescription: 'Dette er et tilbud til deg som er født mellom 2003 og 2016 i skoleåret 21/22. Du kan få 1000 kr som du kan' +
      ' bruke på en eller flere fritidsaktiviteter. Fritidskortet er personlig.' +
      ' Målet med fritidskortet er å bidra til økt deltakelse og inkludering. <br><br>' +
      ' Følg veiledningen under. For mer informasjon se: <a  class="link text-white text-decoration-underline"' +
      ' href="https://namsos.kommune.no/aktuelt/fritidskortet-for-barn-og-unge.10185.aspx"' +
      ' >egen informasjonsside</a>.<br><br>' +
      ' Ved spørsmål ta kontakt på <a  class="link text-white text-decoration-underline"' +
      ' href="mailto:bernt.kristiansen@namsos.kommune.no">bernt.kristiansen@namsos.kommune.no</a> / 402 31 801',
    amount: 1000,
    minAmountOfActivities: 10,
    bankIdStep1: 'NB! FRITIDSKORTET ER for barn født mellom 2003 og 2016 i skoleåret 21/22. ' +
      'DERSOM DU ER FORELDER, LOGG PÅ MED DIN EGEN BANK ID',
    minAge: 6,
    maxAge: 19
  },
  bannerURL: 'https://ucarecdn.com/12bf9610-3904-4dc2-aa30-f94d0aa41da8/nrysund.png',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: true,
  hasSubsidiaryOrg: true,
  sunset: false,
  hasPayment: true,
  hasTickets: true,
  infoSlots: [
    {
      type: InfoType.FRISKUS_LEISURE_CARD,
      title: 'Fritidskonto',
      minAge: 6,
      maxAge: 18,
      url: 'https://namsos.kommune.no/kultur-idrett-og-fritid/fritidskortet/fritidskonto/',
    },
    {
      type: InfoType.FRISKUS_TICKETS,
      name: 'Fritidskonto',
      minAge: 6,
      maxAge: 18,
      url: 'https://namsos.kommune.no/kultur-idrett-og-fritid/fritidskortet/fritidskonto/',
    },
    {
      type: InfoType.EQUIPMENT_RENTAL,
      name: 'BUA',
      url: 'https://www.bua.no/utlansordninger/bua-namsos'
    },
    {
      type: InfoType.VOLUNTEER_CENTERS,
      links: [
        {url: ['/organisations', '4923f71c-d1d4-461b-ad6b-89b7b4b35908'], title: "Namsos Frivilligsentral"},
        {url: ['/organisations', 'baa9e2e4-5f44-4474-ac7b-4aa6dbad1425'], title: "Otterøy Frivilligsentral"},
        {url: ['/organisations', '24b9e214-4d86-4ebd-aa27-e3f033cbad21'], title: "Namdalseid Frivilligsentral"},
      ]
    }
  ]
}
