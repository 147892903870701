import {UserMunicipality} from "@shared/models/user-municipality.model";

export const UllensakerMunicipality: UserMunicipality = {
  name: 'ullensaker',
  slogan: {value: '', id: 'banner.ullensaker', meaning: 'bannerSlogan', description: 'slogan for ullensaker'},
  data: {},
  bannerURL: 'https://ucarecdn.com/07c4a1e0-2742-47f7-a711-cc81c2d1344e/pexelskampusproduction8813512.jpg',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: true,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: true,
  hasTickets: true
}
