import {InfoType, UserMunicipality} from '@shared/models/user-municipality.model';

export const TonsbergDevMunicipality: UserMunicipality = {
  name: 'tonsberg-dev',
  slogan: {
    value: 'Aktivitet og Frivillighet - Test Friskus her <br> Finn din <a class="slogan" href="/info/about/fritidskonto">Fritidskonto</a> her',
    id: 'banner.tonsberg',
    meaning: 'bannerSlogan',
    description: 'slogan for tonsberg'
  },
  data: {
    headerDescription: 'Dette er et tilbud til deg som er mellom 6 til 18 år. Du kan få 1000 kr som du kan' +
      ' bruke på en eller flere fritidsaktiviteter. Fritidskortet er personlig.' +
      ' Målet med fritidskortet er å bidra til økt deltakelse og inkludering.',
    amount: 1000,
    minAmountOfActivities: 10
  },
  bannerURL: 'https://ucarecdn.com/5a92bc8e-2278-4470-bf74-1101e0058287/-/format/auto/',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: true,
  hasSubsidiaryOrg: true,
  sunset: false,
  hasPayment: true,
  hasTickets: true,
  infoPageTitle: 'Fritidskonto',
  infoSlots: [
    {
      type: InfoType.FRISKUS_LEISURE_CARD,
      title: 'Fritidskonto',
      minAge: 6,
      maxAge: 18,
      url: 'https://www.tonsberg.kommune.no/tjenester/kultur-idrett-og-fritid/friskus/',
    },
    {
      type: InfoType.FRISKUS_TICKETS,
      name: 'Fritidskonto',
      minAge: 6,
      maxAge: 18,
      url: 'https://www.tonsberg.kommune.no/tjenester/kultur-idrett-og-fritid/friskus/',
    },
    {
      type: InfoType.EQUIPMENT_RENTAL,
      title: 'Skattkammeret',
      name: 'Skattkammeret',
      url: 'https://www.tonsberg.kommune.no/tjenester/kultur-idrett-og-fritid/utlan-av-sports-og-fritidsutstyr/'
    },
    {
      type: InfoType.FOR_YOUTHS,
      links: [
        {
          url: 'https://www.tonsberg.kommune.no/tjenester/kultur-idrett-og-fritid/ung-i-tonsberg/',
          title: "UNG i Tønsberg"
        },
        {
          url: 'https://www.tonsberg.kommune.no/tjenester/kultur-idrett-og-fritid/ung-i-tonsberg/ungdomsklubber-og-moteplasser/',
          title: "Ungdomsklubb"
        },
        {
          url: 'https://www.tonsberg.kommune.no/tjenester/helse-og-omsorg/helse-barn-og-unge/veiviser-til-tilbud-og-hjelp-for-barn-og-ungdom/',
          title: "Veiviser og hjelp"
        },
      ]
    },
    {type: InfoType.FUNDING, url: 'https://www.tonsberg.kommune.no/tjenester/kultur-idrett-og-fritid/soke-tilskudd/'},
  ]
}
