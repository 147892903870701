import { MetricsService } from './shared/services/remote-api/metrics.service';
import { CookiesService } from './shared/services/cookies/cookies.service';
import { Observable } from 'rxjs';
import { buildDate } from './../version';
import { Component, OnInit, Inject, PLATFORM_ID, Renderer2, LOCALE_ID } from '@angular/core';
import { AuthService } from '@auth/shared/services/auth/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '@environments/environment';
import { UserInfo } from './shared/models/user.model';
import { UsersService } from '@services/remote-api/users.service';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { MetaService } from '@features/auth/shared/services/meta/meta.service';
import { CustomOverlayDialogConfig, DEFAULT_CONFIG } from '@services/overlay/overlay.service';
import { LocalStorageService } from '@services/local-storage/local-storage.service';
import { ScreenTypeService } from '@shared/services/screen-type.service';
import { BreakpointState } from '@angular/cdk/layout';
import { Store } from '@store';

export const SURVEY_INTERVAL_HOURS = 24;
export const SURVEY_TIMEOUT_MINUTES = 1;

@Component({
  selector: 'friskus-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public user: any;
  public userInfo: UserInfo;
  public isOnline: boolean;
  public isLoading: boolean;
  public menuOpened: boolean;
  public messengerUserData: any;
  public overlayConfig: CustomOverlayDialogConfig = DEFAULT_CONFIG;
  public isSurveyShown: boolean;
  public isAnnouncement: boolean;
  private isMobile: Observable<BreakpointState>;

  constructor(
    private router: Router,
    private authService: AuthService,
    private usersService: UsersService,
    private renderer: Renderer2,
    private meta: MetaService,
    private localStorageService: LocalStorageService,
    private cookiesService: CookiesService,
    private store: Store,
    private metricsService: MetricsService,
    private screenType: ScreenTypeService,
    @Inject(DOCUMENT) private doc: Document,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(LOCALE_ID) locale: string) {

    if (isPlatformBrowser(this.platformId)) {
      this.isOnline = navigator.onLine;
      renderer.setAttribute(doc.documentElement, 'lang', locale);
      console.log(`%c This app version was build at ${buildDate}.`, 'background: #461E89; color: #fff');
    } else {
      this.isOnline = true;
    }
  }

  ngOnInit(): void {
    let currentToken: string;
    this.trackUserDevice();
    this.authService.authState.subscribe(user => {
      this.user = user;
      if (this.user && (currentToken !== user.access_token)) {
        currentToken = user.access_token;
        this.isLoading = true;
        this.usersService.getUserInfo(true).subscribe(userInfo => {
          this.userInfo = userInfo.data;
          // Saving user lang to localstorage for future checking
          this.localStorageService.setItem('user-lang', this.userInfo.lang);
          this.isLoading = false;
          if (environment.isLangRedirectEnabled) {
            this.setAppLanguage(this.userInfo.lang);
          }
        });
      }
    });

    this.router.events.subscribe(route => {
      if (route instanceof NavigationEnd && environment.isLangRedirectEnabled) {
         // In first release redirect only authorised users, if needs to be removed later
        if (this.userInfo && environment.isLangRedirectEnabled) {
          const userLang = this.userInfo.lang || null;  // Could set 'no' as default instead of null
          this.setAppLanguage(userLang);
        }
      }

      if (route instanceof NavigationEnd) {
        this.meta.updateMetaProperty('og:url', window.location.href);
        this.meta.updateMetaName('url', window.location.href);
        this.meta.setTitle('Friskus');
        if (environment.gtmId !== '') {
          this.meta.pushGtmTag(route);
        }

      }
    });

    if (!window.location.pathname.includes('/slideshow')) {
      this.isAnnouncement = environment.news;
      this.store.select('announcement').subscribe((announcement: string) => {
        this.isAnnouncement = environment.news || !!announcement;
      });
    }

    // Save user url for correct municipality min id login redirect
    document.cookie = this.cookiesService.setItem(document.cookie, `${environment.prefix}-friskus-user-url`, location.origin, false, '/', '.friskus.com');
  }

  public blockBodyScrolling(event) {
    if (isPlatformBrowser(this.platformId)) {
      event ? this.renderer.addClass(this.doc.body, 'noscroll') : this.renderer.removeClass(this.doc.body, 'noscroll');
    }
  }

  onLogout() {
    this.authService.logout();
    this.router.navigate(['/']);
  }

  private setAppLanguage(userLang: string | null) {
    const redirectRoute = window.location.pathname;
    if (userLang && this.isDefaultLanguage(userLang)) {
      this.navigateToDefaultLanguage(redirectRoute);
      return;
    }

    if (userLang && this.needsRedirectToLanguage(userLang)) {
      this.navigateToLanguage(userLang, redirectRoute);
      return;
    } else {
      const savedUserLang: string = this.localStorageService.getItem('user-lang');

      if (savedUserLang && this.isDefaultLanguage(savedUserLang)) {
        this.navigateToDefaultLanguage(redirectRoute);
        return;
      }

      if (savedUserLang && this.needsRedirectToLanguage(savedUserLang)) {
        this.navigateToLanguage(savedUserLang, redirectRoute);
        return;
      }
      // else {
      //   const browserLang: string = navigator.language.slice(0, 2);
      //   const isSupportedLang = APP_SUPPORTED_LANGUAGES.includes(browserLang);

      //   if (browserLang && isSupportedLang && this.isDefaultLanguage(browserLang)) {
      //     this.navigateToDefaultLanguage(redirectRoute);
      //     return;
      //   }

      //   if (browserLang && isSupportedLang && this.needsRedirectToLanguage(browserLang)) {
      //     this.navigateToLanguage(browserLang, redirectRoute);
      //     return;
      //   }
      // }
    }
  }

  private navigateToLanguage(lang: string, route: string): void {
    let redirectRoute = route;
    if (route.includes(`/nn/`) || route.includes(`/en/`)) {
      redirectRoute = redirectRoute.replace('/nn/', '/').replace('/en/', '/');
    }

    if (redirectRoute) {
      window.location.href = `/${lang}${redirectRoute}`;
    } else {
      window.location.href = `/${lang}`;
    }
  }

  private navigateToDefaultLanguage(route: string): void {
    // Redirect only if on other language
    let redirectRoute: string;
    if (route.includes(`/nn/`) || route.includes(`/en/`)) {
      redirectRoute = route.replace('/nn/', '/').replace('/en/', '/');
    }

    if (redirectRoute) {
      window.location.href = `${redirectRoute}`;
    }
  }

  private isDefaultLanguage(langCode: string): boolean {
    return langCode === 'no' || langCode === 'nb';
  }

  private needsRedirectToLanguage(langCode: string): boolean {
    return !location.pathname.includes(`/${langCode}/`) && !this.isDefaultLanguage(langCode);
  }

  private trackUserDevice() {
    this.isMobile = this.screenType.isHandset;
    this.isMobile.subscribe(res => {
      if (res.matches) {
        this.metricsService.incrementMetrics('userScreenType', 'mobile').subscribe();
      } else {
        this.metricsService.incrementMetrics('userScreenType', 'desktop').subscribe();
      }
    });
  }
}
