import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const SunnfjordMunicipality: UserMunicipality = {
  name: 'sunnfjord',
  slogan: {
    value: '<a class="slogan" href="https://sunnfjord.friskus.com/events?filters=global_filters_municipalities(EQ)1462004f-4ab3-45da-a71d-1e94d0a4e133$$true(AND)events-filterscategories(EQ)4b3931e8-fb87-43ed-864d-0c75f3617973$$false">Konsertar, revy, standup og kino</a> <br>  <br> <a class="slogan" href="https://sunnfjord.friskus.com/events?filters=global_filters_municipalities(EQ)1462004f-4ab3-45da-a71d-1e94d0a4e133$$true(AND)events-filterscategories(EQ)87c373bf-626a-4c74-a5c9-70c9bcf653ff$$false,9d02701d-0e5c-4162-baa1-09cc2ddaf5cb$$false">Idrett, Kultur og Friluftsliv </a>',
    id: 'banner.sunnfjord',
    meaning: 'bannerSlogan',
    description: 'slogan for sunnfjord'
  },
  data: {
    amount: 800,
    headerTitle: 'Fritidskortet for barn i Sunnfjord kommune',
    headerTitleQuestion: ' ',
    headerDescription: ' ',
    headerDescription2: '<div style="text-align:left; color:white;">Sunnfjord kommune har ei ' +
      'fritidskort-ordning som gir støtte til fritidsaktivitetar for ' +
      'barn og unge i husstandar med samla brutto årsinntekt under 560 000 kroner. ' +
      'Fritidskortet er eit tilbod til born og unge i låginntektsfamiliar med ' +
      'bustadadresse i Sunnfjord kommune. Kvart barn kan få ein gratis ' +
      'fritidsaktivitet kvart år. </div><br>' +
      '<div style="text-align:left; color:white;">Føresette må gje skriftleg stadfesting på at familien er i målgruppa for ' +
      'ordninga. Dette kan du gjere når du melder barnet på aktiviteten.</div>' +
      '<ul style="text-align:left;"><li>Eit fritidskort tilhøyrer kvart enkelt barn. Det kan ikkje bli overført til ' +
      'søsken eller andre barn.</li>' +
      '<li>Fritidskortet gjeld for barn og unge frå og med 6 til og med året du fyller 18 år.</li></ul>' +
      '<div style="text-align:left; color:white;">Fritidskortet bidrar til å dekke utgifter til medlemskap og aktivitetsavgift i ' +
      'kor, korps, idrett, 4H eller liknande. Aktiviteten må skje minst 10 gongar i løp av ein sesong.</div><br>' +
      '<div style="text-align:left; color:white;">Meir informasjon om fritidskortet i Sunnfjord finn du på ' +
      '<a class="link text-white text-decoration-underline" target="_blank" ' +
      'href="https://sunnfjord.kommune.no/aktuelt-fra-kommunen/fritidskort-for-barn-i-sunnfjord-kommune.24476.aspx">' +
      'Sunnfjords egen infoside.</a></div>'
  },
  bannerURL: 'https://s3.eu-north-1.amazonaws.com/media.friskus.com/banners/sunnfjord.jpg',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: true,
  hasSubsidiaryOrg: true,
  sunset: false,
  hasPayment: true,
  hasTickets: false,
  infoPageTitle: 'Fritidskortet',
  infoSlots: [
    {
      type: InfoType.FRISKUS_LEISURE_CARD,
      title: 'Fritidskortet',
      minAge: 6,
      maxAge: 18,
      url: 'https://sunnfjord.kommune.no/aktuelt-fra-kommunen/fritidskort-for-barn-i-sunnfjord-kommune.24476.aspx',
    },
    {
      type: InfoType.EQUIPMENT_RENTAL,
      name: 'BUA',
      url: 'https://sunnfjord.kommune.no/tenester/kultur-idrett-og-friluftsliv/idrett-og-friluftsliv/bua-utstyrssentral/'
    },
    {
      type: InfoType.FUNDING,
      url: 'https://sunnfjord.kommune.no/tenester/kultur-idrett-og-friluftsliv/tilskotsordningar/'
    },
    {
      type: InfoType.FOR_YOUTHS,
      links: [
        {url: ['/organisations', '2625aab3-b5af-4930-aa69-1f81a7508f34'], title: "Skrivarløa Ungdomshus"},
        {url: ['/organisations', 'dfaa747e-a83a-4081-ade9-0d4d0f129679'], title: "Naustdal Ungdomslag"},
        {url: ['/organisations', '2fd44b37-1347-42f3-bc04-bb785f411f92'], title: "Førde Ungdomslag"},
        {url: ['/organisations', 'f8c5cbd7-2172-49a6-975a-ca88fecf8591'], title: "Grill'n chill"},
      ]
    },
  ]
}
