import {UserMunicipality} from "@shared/models/user-municipality.model";

export const FauskeMunicipality: UserMunicipality = {
  name: 'fauske',
  slogan: {},
  data: {},
  bannerURL: 'https://ucarecdn.com/99bd8816-2492-4f8c-b771-a8532f75c37a/-/format/auto/',
  bannerType: 'image',
  hasLeisureCard: false,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: true,
  hasPayment: false,
  hasTickets: false
}
