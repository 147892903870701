import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const LevangerMunicipality: UserMunicipality = {
  name: 'levanger',
  slogan: {value: '', id: 'banner.levanger', meaning: 'bannerSlogan', description: 'slogan for levanger'},
  data: {
    headerDescription: 'Dette er et tilbud til deg som er mellom 6 til 18 år. Du kan få 1000 kr som du kan' +
      ' bruke på en eller flere fritidsaktiviteter. Fritidskortet er personlig.' +
      ' Målet med fritidskortet er å bidra til økt deltakelse og inkludering. <br><br>' +
      ' Følg veiledningen under. For mer informasjon se: <a class="link text-white text-decoration-underline"' +
      ' href="https://www.levanger.kommune.no/Nyheter/Siste-nyheter/Fritidskort-for-barn-og-ungdom/"' +
      ' >egen informasjonsside</a>.<br><br>' +
      ' Ved spørsmål ta kontakt på <a class="link text-white text-decoration-underline"' +
      ' href="mailto:inradw@levanger.kommune.no">inradw@levanger.kommune.no</a> / 930 20 217<br><br>' +
      ' eller kontakt <a  class="link text-white text-decoration-underline"' +
      ' href="mailto:servicekontoret@levanger.kommune.no">servicekontoret@levanger.kommune.no</a> / 74 05 25 00',
    amount: 1500,
    minAmountOfActivities: 10
  },
  bannerURL: '',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: true,
  hasSubsidiaryOrg: true,
  sunset: false,
  hasPayment: true,
  hasTickets: true,
  infoPageTitle: 'Info',
  infoSlots: [
    {
      type: InfoType.FRISKUS_LEISURE_CARD,
      title: 'Fritidskortet',
      minAge: 6,
      maxAge: 18,
      url: 'https://www.levanger.kommune.no/tjenester/kultur-idrett-og-fritid/barn-og-ungdom/',
    },
    {
      type: InfoType.EQUIPMENT_RENTAL,
      name: 'BUA',
      url: 'https://www.bua.no/utlansordninger/bua-levanger',
    },
    {
      type: InfoType.FUNDING,
      url: 'https://www.levanger.kommune.no/tjenester/kultur-idrett-og-fritid/lag-og-foreninger/sok-om-tilskudd/',
    },
    {
      type: InfoType.FOR_YOUTHS,
      links: [
        {url: ['/organisations', 'f6a51604-e43c-410e-a832-28c6bbcd8644'], title: "Levanger Ungdomshus"},
        {url: ['/organisations', 'f4d52f12-fc27-4146-9de9-5949e7c5653b'], title: "Ytterøy Ungdomshus"},
        {url: ['/organisations', 'c7ad8f83-0126-481e-96f3-a694fc9d01a0'], title: "Levanger Ungdomsråd"},
      ]
    },
    {
      type: InfoType.VENUE,
      url: "https://www.bookup.no/Utleie/#___/view:list/q:levanger/mod:book",
    },
    {
      type: InfoType.VOLUNTEER_CENTRE,
      url: ['/organisations', '52a4edde-c98d-4227-840d-f2ffe5b6738c']
    },
    {
      type: InfoType.FOR_SENIORS,
      links: [
        {url: ['/organisations', '00b91412-6627-487a-a91a-5689dc86da02'], title: "Innherred Seniorforum"},
        {url: ['/organisations', '4dc1dd93-14f7-48a1-ba88-5fb83a95f06d'], title: "Skogn Pensjonistlag"},
        {url: ['/organisations', '96404d3e-e2a5-4b2c-a1b0-2e83a9872df3'], title: "Levanger og Frol Pensjonistlag"},
      ]
    },

  ]
}
