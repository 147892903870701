import {UserMunicipality} from '@shared/models/user-municipality.model';

export const SamnangerMunicipality: UserMunicipality = {
  name: 'samnanger',
  slogan: {},
  data: {},
  bannerURL: '',
  bannerType: 'image',
  hasLeisureCard: false,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: true,
  sunset: false,
  hasPayment: false,
  hasTickets: false,
  infoPageTitle: "Fritidskonto"
}
