import {UserMunicipality} from '@shared/models/user-municipality.model';

export const DyroyMunicipality: UserMunicipality = {
  name: 'dyroy',
  slogan: {},
  data: {},
  bannerURL: '',
  bannerType: 'image',
  hasLeisureCard: false,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: true,
  hasSubsidiaryOrg: true,
  sunset: false,
  hasPayment: false,
  hasTickets: false,
  infoPageTitle: 'Fritidskonto'
}
