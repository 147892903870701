import { Component, OnInit } from "@angular/core";
import { MunicipalitiesService } from "@services/remote-api/municipalities.service";

@Component({
  selector: "friskus-tickets-marketplace-link",
  templateUrl: "./tickets-marketplace-link.component.html",
  styleUrls: ["./tickets-marketplace-link.component.scss"]
})
export class TicketsMarketplaceLinkComponent implements OnInit {
  public municipalityName: string;

  constructor(private municipalitiesService: MunicipalitiesService) {}

  ngOnInit() {
    this.municipalitiesService.municipalities.subscribe(municipalities => {
      const municipality = municipalities.data.find(x => x.key === this.municipalitiesService.getUserMunicipality().name);
      this.municipalityName = municipality.name;
    });
  }

}
