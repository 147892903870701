import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const VossDevMunicipality: UserMunicipality = {
  name: 'voss-dev',
  slogan: {value: 'AKTIVITETAR', id: 'banner.voss', meaning: 'bannerSlogan', description: 'slogan for voss'},
  data: {},
  bannerURL: '',
  bannerType: 'image',
  hasLeisureCard: false,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: false,
  hasTickets: false,
  infoPageTitle: 'Støtteordningar',
  infoSlots: [
    {
      type: InfoType.EQUIPMENT_RENTAL,
      name: 'Skattkammeret Voss',
      url: 'https://kirkensbymisjon.no/skattkammeret/voss/'
    },
    {
      type: InfoType.OTHER,
      links: [
        {
          title: 'Friplass i Voss kulturskule',
          url: 'https://voss.herad.no/aktuelt-fra-kommunen/friplass-i-voss-kulturskule.3311.aspx'
        },
        {title: 'Fritidsfondet', url: ['/organisations', 'd27cebb5-6504-45b5-9991-0c52e5030fb2']},
        {title: 'Aktivitestkortet', url: ['/organisations', '781a312e-be49-4395-91d0-7262aa40734f']},
      ]
    },
    {
      type: InfoType.SUPPORT_CONTACT,
      url: 'https://voss.herad.no/tenester/helse-sosial-og-omsorg/avlastning-og-stotte/stottekontakt/'
    },
    {
      type: InfoType.FUNDING,
      url: 'https://voss.herad.no/tenester/kultur-idrett-og-fritid/tilskot/'
    },
  ]
}
