import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const TysnesMunicipality: UserMunicipality = {
  name: 'tysnes',
  slogan: {value: '', id: 'banner.tysnes', meaning: 'bannerSlogan', description: 'slogan for tysnes'},
  data: {},
  bannerURL: '',
  bannerType: 'image',
  hasLeisureCard: false,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: false,
  hasTickets: false,
  infoSlots: [
    {
      type: InfoType.EQUIPMENT_RENTAL,
      name: 'BUA',
      url: 'https://www.bua.no/utlansordninger/bua-tysnes'
    },
    {type: InfoType.FUNDING, url: 'https://www.tysnes.kommune.no/tilskot.530797.nn.html'},
  ]
}
