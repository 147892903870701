import {UserMunicipality} from "@shared/models/user-municipality.model";

export const SveioMunicipality: UserMunicipality = {
  name: 'sveio',
  slogan: {value: '', id: 'banner.sveio', meaning: 'bannerSlogan', description: 'slogan for sveio'},
  data: {},
  bannerURL: '',
  bannerType: 'image',
  hasLeisureCard: false,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: false,
  hasTickets: false
}
