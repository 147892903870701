import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const VoldaMunicipality: UserMunicipality = {
  name: 'volda',
  slogan: {
    value: 'Aktiviteter og frivillige oppdrag - nær deg', id: 'banner.volda', meaning: 'bannerVolda',
    description: 'slogan for volda'
  },
  data: {},
  bannerURL: 'https://ucarecdn.com/d76bc1c8-df44-4727-ac47-4dfc84e9f672/banner_main_updated.jpg',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: true,
  hasSubsidiaryOrg: true,
  sunset: false,
  hasPayment: true,
  hasTickets: false,
  infoPageTitle: 'Gode ordningar',
  infoSlots: [
     {
      type: InfoType.FRISKUS_LEISURE_CARD,
      title: 'Fritidskonto',
      minAge: 6,
      maxAge: 19,
      url: 'https://www.volda.kommune.no/tenester/kultur-og-fritid/fritidskonto-for-barn-og-unge/',
    },
    {
      type: InfoType.EQUIPMENT_RENTAL,
      name: 'BUA',
      url: 'https://www.bua.no/utlansordninger/bua-volda'
    },
    {
      type: InfoType.FUNDING,
      url: 'https://www.volda.kommune.no/tenester/kultur-og-fritid/tilskot-stipend-og-utmerkingar/'
    },
    {
      type: InfoType.VOLUNTEER_CENTRE,
      url: ['/organisations', 'a8429ad2-35cc-45d0-bf30-d34cdeb15009']
    },
     {
      type: InfoType.FOR_SENIORS,
       links: [
      {title: 'Dagtilbod for eldre', url: 'https://www.volda.kommune.no/tenester/helse-og-omsorg/dagtilbod-og-aktivitetar/dagtilbod-for-eldre/'},
      ]   
     },
  ]
}
