import {UserMunicipality} from '@shared/models/user-municipality.model';

export const HaramMunicipality: UserMunicipality = {
  name: 'haram',
  slogan: {},
  data: {
    minAge: 6,
    maxAge: 18
  },
  bannerURL: 'https://ucarecdn.com/5afe5ca7-fb28-4d37-8ecd-a481b140824b/Nordoyvegen_Lepsoybrua_Lepsoyrevet_fyr_ALD_33610min.jpeg',
  bannerType: 'image',
  hasLeisureCard: false,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: false,
  hasTickets: false
}
