import {UserMunicipality} from "@shared/models/user-municipality.model";

export const KautokeinoMunicipality: UserMunicipality = {
  name: 'kautokeino',
  slogan: {value: '', id: 'banner.kautokeino', meaning: 'bannerSlogan', description: 'slogan for kautokeino'},
  data: {
    headerDescription: 'Fritidskortordningen er avsluttet. Her vil det om kort tid komme informasjon om øvrige støtteordninger.'
  },
  bannerURL: '',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: true,
  hasTickets: false
}
