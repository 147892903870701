import {UserMunicipality} from '@shared/models/user-municipality.model';

export const FredrikstadMunicipality: UserMunicipality = {
  name: 'fredrikstad',
  slogan: {
    value: 'Aktiviteter og frivillige oppdrag - nær deg'
  },
  data: {},
  bannerURL: 'https://ucarecdn.com/2ba87b6b-3300-434c-97db-0744555242bb/-/preview/2000x600/',
  bannerType: 'image',
  hasLeisureCard: false,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: false,
  hasTickets: false,
  infoPageTitle: "Fritidskonto"
}
