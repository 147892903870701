import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const UlsteinMunicipality: UserMunicipality = {
  name: 'ulstein',
  slogan: {value: '', id: 'banner.ulstein', meaning: 'bannerSlogan', description: 'slogan for ulstein'},
  data: {
    headerDescription: '',
    amount: 0,
    minAmountOfActivities: 10
  },
  bannerURL: '',
  bannerType: 'image',
  hasLeisureCard: false,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: true,
  hasPayment: false,
  hasTickets: false,
  infoSlots: [
    {
      type: InfoType.EQUIPMENT_RENTAL,
      name: 'BUA',
      url: 'https://www.bua.no/utlansordninger/bua-ulstein'
    },
    {
      type: InfoType.FUNDING,
      url: 'https://www.ulstein.kommune.no/tenester/kultur-og-fritid/tilskot-stipend-og-utmerkingar/'
    },
  ]
}
