<div class="position-relative d-flex flex-column w-100 bg-primary rounded justify-content-center align-items-center p-3 mb-4">
  <p class="color-accent-green font-size-sm w-full m-0"
     i18n="Municipality offers these tickets@@label.municipalityOffers"
     >{{municipalityName}} municipality offers</p>
  <p class="color-white font-size-lg font-weight-bold w-full m-0 mb-3"
     i18n="Municipality offers@@hint.freeTickets"
    >Free tickets to experiences</p>
  <div class="position-absolute top-full">
    <a
      [routerLink]="['/tickets', 'marketplace']"
      i18n="Explore marketplace@@label.exploreMarketplace"
    >Explore marketplace</a>
  </div>
</div>
