import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const RandabergMunicipality: UserMunicipality = {
  name: 'randaberg',
  slogan: {value: '', id: 'banner.randaberg', meaning: 'bannerSlogan', description: 'slogan for alesund'},
  data: {},
  bannerURL: 'https://ucarecdn.com/80a38d7e-cee9-4252-848d-9020f2653d92/-/preview/1000x667/',
  bannerType: 'image',
  hasLeisureCard: false,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: false,
  hasTickets: false,
  infoPageTitle: 'Fritidskonto',
}
