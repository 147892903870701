import { MatDialog } from '@angular/material/dialog';
import { LeisureCardService } from '@services/remote-api/leisure-card.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LeisureCard} from '@shared/models/leisure-card.model';
import { ShareCardComponent } from '../dialogs/share-card/share-card.component';
import { MODAL_SIZE } from '@shared/constants/modals.const';
import { AuthService } from '@features/auth/shared/services/auth/auth.service';

@Component({
  selector: 'friskus-leisure-card',
  templateUrl: './leisure-card.component.html',
  styleUrls: ['./leisure-card.component.scss']
})
export class LeisureCardComponent implements OnInit {
  @Input() data: LeisureCard;
  @Input() hasPayment: boolean;
  @Input() hasTickets: boolean;
  @Output() cardsChanged = new EventEmitter();
  public isLoading: boolean;
  public card: any = {};
  public showPaymentHistory = false;
  private currentUserId: string|null;

  constructor(
    private leisureCardService: LeisureCardService,
    private dialog: MatDialog,
    private authService: AuthService,
  ) {
  }

  ngOnInit() {
    if (this.data) {
      this.card = this.data;
      this.showCardHistory(this.card.id);
    }
    this.currentUserId = this.authService.loggedInUserId;
  }

  isFailed(transaction) {
    return [2, 5].includes(transaction.payment_status_code);
  }

  public showCardHistory(cardId: string) {
    this.isLoading = true;
    this.leisureCardService.getCardHistory(cardId).subscribe(res => {
      this.card.history = res.data;
      this.isLoading = false;
    });
  }

  public showShare(card) {
    return card.user.id === this.currentUserId;
  }

  public share(card) {
    this.leisureCardService.shareCard(card.id).subscribe(
      (res) => {
        this.dialog.open(ShareCardComponent, {
          data: {
            ssn: res.data.ssn,
            pin: res.data.pin
          },
          maxWidth: MODAL_SIZE.medium,
          width: MODAL_SIZE.medium,
          panelClass: 'full-width-dialog',
          disableClose: true
        });
      }
    )
  }

  public hasPaymentHistory() {
    return this.card.history !== undefined && this.card.history.length > 0;
  }

  retry(transaction) {
    transaction.isLoading = true;
    this.leisureCardService.retryTransaction(transaction.id).subscribe(res => {
      transaction.isLoading = false;
      this.cardsChanged.emit();
    });
  }
}
