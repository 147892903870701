import {UserMunicipality} from "@shared/models/user-municipality.model";

export const KristiansundMunicipality: UserMunicipality = {
  name: 'kristiansund',
  slogan: {value: '', id: 'banner.kristiansund', meaning: 'bannerSlogan', description: 'slogan for kristiansund'},
  data: {
    headerDescription: 'Fritidskortordningen er avsluttet. Her vil det om kort tid komme informasjon om øvrige støtteordninger.'
  },
  bannerURL: 'https://ucarecdn.com/bcd7516a-a3a4-4fdc-a217-177ed66f4716/kristiansund_portal.jpg',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: true,
  hasTickets: false
}
