import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const VaksdalMunicipality: UserMunicipality = {
  name: 'vaksdal',
  slogan: {value: '', id: 'banner.vaksdal', meaning: 'bannerSlogan', description: 'slogan for vaksdal'},
  data: {},
  bannerURL: '',
  bannerType: 'image',
  hasLeisureCard: false,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: false,
  hasTickets: false,
  infoSlots: [
    {
      type: InfoType.EQUIPMENT_RENTAL,
      name: 'BUA',
      url: 'https://www.bua.no/utlansordninger/bua-vaksdal'
    },
    {
      type: InfoType.FUNDING,
      url: 'https://vaksdal.kommune.no/innhald/natur-kultur-og-fritid/kultur-og-idrett/kulturmidlar-kulturpris/'
    },
  ]
}
