import {UserMunicipality} from "@shared/models/user-municipality.model";

export const BjornafjordenMunicipality: UserMunicipality = {
  name: 'bjornafjorden',
  slogan: {value: '', id: 'banner.bjornafjorden', meaning: 'bannerSlogan', description: 'slogan for bjornafjorden'},
  data: {},
  bannerURL: 'https://ucarecdn.com/b41ff439-88cb-4e02-9da3-c77edca864c4/profilbilde1.jpg',
  bannerType: 'image',
  hasLeisureCard: false,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: false,
  hasTickets: false
}
